String.random = function (length = 8) {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz'.split('')

  let str = new String()
  length.times(i => str += chars[Math.floor(Math.random() * chars.length)])

  return str
}

String.prototype.isBlank = function () {
  return (this.length === 0) || !this.trim()
}

Number.prototype.times = function (callback) {
  return range(1, this, true).map(time => callback(time))
}

Number.prototype.to = function (max) {
  const arr = []
  for (let start = parseInt(this, 10), i = start, end = max, asc = start <= end; asc ? i <= end : i >= end; asc ? i++ : i--)
    arr.push(i)

  return arr
}

Number.prototype.format = function (decimals = 0, dec_point = '.', thousands_sep = ',') {
  const re = `\\d(?=(\\d{${ 3 }})+${ decimals > 0 ? '\\D' : '$' })`
  const num = this.toFixed(Math.max(0, ~~decimals))
  return (dec_point ? num.replace('.', dec_point) : num).replace(new RegExp(re, 'g'), `$&${ thousands_sep}`)
}

Number.prototype.padLeft = function (base, chr) {
  const len = (String(base || 10).length - (String(this).length)) + 1
  if (len > 0)
    return new Array(len).join(chr || '0') + this
  else
    return this
}

Date.prototype.addDays = function (days) {
  let date = this
  if (!(date instanceof Date)) date = new Date()

  date.setDate(date.getDate() + parseInt(days, 10))

  return date
}

const toFixedFix = (n, prec) => {
  const k = Math.pow(10, prec)

  return (Math.round(n * k) / k).toFixed(prec).toString()
}

const range = (left, right, inclusive) => {
  const range = []
  const ascending = left < right
  const end = !inclusive ? right : ascending ? right + 1 : right - 1
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) range.push(i)
  return range
}

document.querySelectorAll('a[href^="#"]').forEach(a => {
  a.addEventListener('click', function (e) {
    $('html, body').stop()
    e.preventDefault()
    const href = this.getAttribute('href')

    let scrollTop = 0

    if (href.length !== 1 && href !== '#') {
      const elem = document.querySelector(href) || document.querySelector(`a[name=${href.substring(1, href.length)}]`)
      scrollTop = elem.offsetTop + 103
    }

    window.scroll({
      top: scrollTop,
      left: 0,
      behavior: 'smooth'
    })

    setTimeout(() => {
      window.location.hash = this.hash
    }, 200)
  })
})

$(function () {
  $.fn.size = function () {
    return this.length
  }
})
